<template>
  <DynamicForm
      :schema="formSchema"
      :button-data="submitButton"
      :is-disabled="isDisabled"
      form-class="justify-content-start"
      @submit="submit"
    >
      <template v-slot:error>
        <div class="server-error-message content mt-3" v-if="errorMessage.length">
          {{ errorMessage }}
          <button @click.prevent="errorMessage = ''" class="feather icon-x button"></button>
        </div>
      </template>
  </DynamicForm>
</template>

<script>
import DynamicForm from './DynamicForm.vue'
import { useAddSandboxRecipient } from '@/application/composables/addSandboxRecipient.js'

export default {
  name: 'AddRecipientForm',
  components: {
    DynamicForm,
  },
  props: {
    license: {
      type: Object,
    },
  },
  setup(props) {
    const {
      formSchema,
      submitButton,
      submit,
      isDisabled,
      errorMessage,
    } = useAddSandboxRecipient(props.license.license_key, props.license.secret_api_key)
    return {
      formSchema,
      submitButton,
      submit,
      isDisabled,
      errorMessage,
    }
  },
}
</script>
