import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { addSandboxUser } from '@/application/services/dashboard.js'
import { trimValues } from '@/application/utils/trim.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'

export function useAddSandboxRecipient(license_key, secret_api_key) {
  const i18n = useI18n()
  const store = useStore()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { responseErrorHandler } = useErrorHandler()
  const formSchema = computed(() => [
    {
      label: i18n.t('addSandboxRecipients'),
      name: 'contact',
      as: 'input',
      type: 'text',
      rules: 'required|phone_email',
      cols: ' col-12',
      inputClass: 'form-control',
      description: i18n.t('addRecipientSandboxInputInfo'),
    },
  ])
  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary mb-4 mt-4',
    text: i18n.t('addRecipient'),
  }))
  const submit = (value, { resetForm }) => {
    isDisabled.value = true
    const formatted = trimValues(value)
    addSandboxUser(formatted, {
      'Loop-Secret-Key': secret_api_key,
    })
      .then(() => {
        isDisabled.value = false
        store.commit('addSandboxContact', {
          license_key,
          contact: value.contact,
        })
        resetForm()
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
      })
  }
  return {
    formSchema,
    submitButton,
    submit,
    isDisabled,
    errorMessage,
  }
}
